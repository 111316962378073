import React from "react"

const SkillIcon = ({ name, src }) => {
  return (
    <div className="transition ease-in-out hover:scale-110 duration-300 lg:mx-4">
      <img
        className="w-7 sm:w-8 md:w-10"
        data-tip={name}
        src={src}
        alt={`${name} icon`}
      />
    </div>
  )
}

export default SkillIcon
