import React from "react"
import ReactTooltip from "react-tooltip"
import GridWrapper from "../../components/GridWrapper"
import Headline from "../../components/Headline"
import SkillIcon from './SkillIcon'

// icons
import html5 from "../../images/html5.svg"
import css3 from "../../images/css3.svg"
import javascript from "../../images/javascript.svg"
import typescript from "../../images/typescript.svg"
import react from "../../images/react.svg"
import ruby from "../../images/ruby.svg"
import postgresql from "../../images/postgresql.svg"
import gatsby from "../../images/gatsby.svg"
import graphql from "../../images/graphql.svg"
import nodejs from "../../images/nodejs.svg"
import figma from "../../images/figma.svg"
import adobe from "../../images/adobe.svg"


const SKILLS_LIST = [
  {name: 'JavaScript', src: javascript},
  {name: 'TypeScript', src: typescript},
  {name: 'React', src: react},
  {name: 'Ruby', src: ruby},
  {name: 'HTML5', src: html5},
  {name: 'CSS3', src: css3},
  {name: 'Gatsby', src: gatsby},
  {name: 'GraphQL', src: graphql},
  {name: 'Node.js', src: nodejs},
  {name: 'PostgreSQL', src: postgresql},
  {name: 'Figma', src: figma},
  {name: 'Adobe Creative Suite', src: adobe}
]

const Experience = () => {
  return (
    <GridWrapper>
      <div className="md:block hidden"></div>
      <section
        id="experience"
        className="flex flex-col justify-start max-w-full py-14 sm:py-20 md:py-38 text-offWhite z-10"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:items-center">
          <div>
            <h2
              className="uppercase mb-3 md:mb-4 text-lightGray text-xs tracking-[0.2em] font-[500]"
              data-sal="slide-down"
              data-sal-delay="150"
              data-sal-easing="ease"
              data-sal-duration="2000"
            >
              Languages, Frameworks, and Tools
            </h2>
            <Headline
              title="I love JavaScript"
              titleTwo="React."
              subtitle="and I stay hooked on"
            />
            <div
              className="text-sm md:text-base leading-6 max-w-full sm:max-w-md md:max-w-lg lg:max-w-xl text-offWhite mt-10 md:mt-12"
              data-sal="slide-down"
              data-sal-delay="150"
              data-sal-easing="ease"
              data-sal-duration="2000"
            >
              <p>
                Life comes at you fast, but technology is faster, which is why
                I'm always eager to learn the latest advancements in tech. My
                foundation is built on a comprehensive knowldege of HTML5, CSS3,
                and JavaScript (ES6+), but I enjoy working cross-functionally on backend micro-services, RESTful APIs, and testing services using Ruby, SQL, and Golang.
                I'm passionate about React and its ecosystem. So much so
                that I picked up React Native in order to build{" "}
                <a
                  className="text-accentYellow underline underline-offset-2 decoration-solid hover:text-accentBlue transition duration-150"
                  href="https://expo.dev/@cbilladeau/nh-companion"
                  target="_blank"
                  rel="noreferrer"
                >
                  an iOS app,
                </a>{" "}
                which I released to the App Store in 2020.
              </p>
            </div>
          </div>
          <div
            data-sal="slide-down"
            data-sal-delay="150"
            data-sal-easing="ease"
            data-sal-duration="2000"
            id="icons"
            className="grid grid-cols-4 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-12 lg:pl-12 lg:mx-auto lg:max-w-full md:max-w-sm max-w-[18rem] lg:m-0 mt-10"
          >
            {SKILLS_LIST.map((skill) => (
                <SkillIcon
                name={skill.name}
                src={skill.src}
              />)
            )}
            <ReactTooltip />
          </div>
          {/* SKILLS */}
          <div
            data-sal="slide-down"
            data-sal-delay="150"
            data-sal-easing="ease"
            data-sal-duration="2000"
            id="skills"
            className="grid grid-cols-1 sm:grid-cols-2 max-w-2xl mt-6"
          >
            <div className="flex flex-col justify-start mt-8 max-w-sm md:max-w-full">
              <h4 className="uppercase mb-3 md:mb-4 text-accentYellow text-xs tracking-[0.2em] font-[500]">
                Languages
              </h4>
              <ul className="text-sm grid grid-cols-2 gap-2">
                <li>JavaScript</li>
                <li>TypeScript</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>GraphQL</li>
                <li>Ruby</li>
                <li>SQL</li>
                <li>Golang</li>
              </ul>
            </div>
            <div className="flex flex-col justify-start mt-8 max-w-sm md:max-w-full">
              <h4 className="uppercase mb-3 md:mb-4 text-accentYellow text-xs tracking-[0.2em] font-[500]">
                Libraries & Frameworks
              </h4>
              <ul className="text-sm grid grid-cols-2 gap-2">
                <li>React</li>
                <li>React Native</li>
                <li>Redux</li>
                <li>React Testing Library</li>
                <li>Jest</li>
                <li>Gatsby</li>
                <li>jQuery</li>
                <li>Express</li>
                <li>styled components</li>
                <li>Tailwind CSS</li>
                <li>Motion</li>
              </ul>
            </div>
            <div className="flex flex-col justify-start mt-8 max-w-sm md:max-w-full">
              <h4 className="uppercase mb-3 md:mb-4 text-accentYellow text-xs tracking-[0.2em] font-[500]">
                Tools & Backend
              </h4>
              <ul className="text-sm grid grid-cols-2 gap-2">
                <li>Git</li>
                <li>Bitbucket</li>
                <li>Github</li>
                <li>PostgreSQL</li>
                <li>Node.js</li>
                <li>Drupal</li>
                <li>Grafana</li>
                <li>Datadog</li>
                <li>WordPress</li>
                <li>Webpack</li>
                <li>MongoDB</li>
                <li>mySQL</li>
              </ul>
            </div>
            <div className="flex flex-col justify-start mt-8 max-w-sm md:max-w-full">
              <h4 className="uppercase mb-3 md:mb-4 text-accentYellow text-xs tracking-[0.2em] font-[500]">
                Design & Development
              </h4>
              <ul className="text-sm grid grid-cols-2 gap-2">
                <li>JIRA</li>
                <li>Figma</li>
                <li>Integration Testing</li>
                <li>Agile Development</li>
                <li>Quality Assurance</li>
                <li>Technical Writing</li>
                <li>Adobe Creative Suite</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="md:block hidden"></div>
    </GridWrapper>
  )
}

export default Experience
