import React from "react"
import { Link } from "gatsby"

const PortfolioItem = ({ title, link }) => {
  return (
    <li className="py-1">
      <Link
        href={link}
        className="text-offWhite hover:text-accentYellow transition duration-150 ease-in-out hover:underline"
      >
        {title}
      </Link>
    </li>
  )
}

export default PortfolioItem
