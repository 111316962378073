import React from "react"
import GridWrapper from "../../components/GridWrapper"
import Headline from "../../components/Headline"
import SubText from "../../components/SubText"

const About = () => {
  return (
    <GridWrapper>
      <div className="md:block hidden"></div>
      <section
        id="about"
        className="flex flex-col justify-start py-14 sm:py-20 md:py-36 2xl:py-48 text-offWhite z-10"
      >
        <h2
          className="uppercase mb-2 md:mb-6 text-lightGray text-xs tracking-[0.2em] font-[500]"
          data-sal="slide-down"
          data-sal-delay="150"
          data-sal-easing="ease"
          data-sal-duration="2000"
        >
          Get to know me
        </h2>

        <Headline
          title="Quality assurance tester"
          titleTwo="teacher"
          titleThree="frontend developer."
          subtitle="turned"
          subtitleTwo="turned"
        />
        <h3
          className="uppercase mb-3 md:mb-4 mt-10 text-accentYellow text-xs tracking-[0.2em] font-[500]"
          data-sal="slide-down"
          data-sal-delay="150"
          data-sal-easing="ease"
          data-sal-duration="2000"
        >
          Hi, I'm Charlie Billadeau
        </h3>
        <SubText
          noMarginTop
          subText="I bet you’re wondering how I got here. Turns out spending hours getting frighteningly good at breaking video game software during development sprints leads you down some interesting paths in the wider software industry. In my case, it eventually lead me into programming and building some cool apps."
        />
        <SubText subText="Currently, I'm a frontend software engineer at ReviewTrackers, a review management SaaS company. Before that, I was a web developer at the Minnesota Timberwolves & Lynx, where I have led the development and transition of our team sites & codebase from Drupal to WordPress and automated our website generation with Gatsby." />
      </section>
      <div className="md:block hidden"></div>
    </GridWrapper>
  )
}

export default About
